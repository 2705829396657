module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FYMN5SQL9P"],"pluginConfig":{"exclude":["/preview/**","/admin/**","/docs/**"],"head":false,"respectDNT":false,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Omidyar Fellows","short_name":"OF","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"minimal-ui","icon":"/opt/build/repo/src/assets/manifest-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b9dbf37551fbb7f0005312d4cd3c3a75"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"gtc1ovo"},"google":{"families":["PT Serif","PT Serif:ital"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"omidyarfellows","accessToken":"MC5aWlN1c0JBQUFJV04xbXFB.XO-_vU_vv73vv70WJDTvv73vv71NRnTvv71fEe-_vWR877-977-977-977-977-977-9M--_ve-_vXdL77-9Vg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
