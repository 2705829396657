exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-interior-page-tsx": () => import("./../../../src/templates/interior_page.tsx" /* webpackChunkName: "component---src-templates-interior-page-tsx" */),
  "component---src-templates-news-category-tsx": () => import("./../../../src/templates/news_category.tsx" /* webpackChunkName: "component---src-templates-news-category-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news_post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-project-category-tsx": () => import("./../../../src/templates/project_category.tsx" /* webpackChunkName: "component---src-templates-project-category-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */)
}

